import React, {useState} from 'react';
/* import PropTypes from 'prop-types'; */
import './Login.scss';
import { Auth } from "aws-amplify";
import {Button, Card} from 'ui-neumorphism';
import { useHistory } from 'react-router-dom';
import NavBarHome from '../NavBarHome/NavBarHome';

const Login = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const setUsernameVal = (event) => {
    setUsername(event.target.value);
  };

  const setPasswordVal = (event) => {
    setPassword(event.target.value);
  };

  const login = () => {
    setLoading(true);
    Auth.signIn(username, password)
      .then((res) => {
        history.push('/listings');
        setLoading(false);
      })
      .catch((err) => {
        // this.showFlashMessage(err.message, "red");
        setLoading(false);
      });
  }

  const goToRegister = () => {
    history.push('/register');
  }

  return (<div>
            <NavBarHome />
            <div className="container">
              <Card className="container"  data-testid="Login">
                  <h1>Login</h1>
                  <Card rounded className="text" inset><input value={username} onChange={setUsernameVal}  placeholder="UserName" /></Card>
                  <Card rounded className="text" inset><input type="password" value={password} onChange={setPasswordVal}  placeholder="Password" /></Card>
                  <Button rounded disabled={loading}  className="btn" onClick={login} color='#31344b'>Login</Button>
                  <div><div className="link" onClick={goToRegister}>Create Account</div></div>
              </Card>
            </div>
          </div>);
}
  

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
