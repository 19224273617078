import Amplify from "@aws-amplify/core";
import config from "./aws-exports";
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom";
import 'ui-neumorphism/dist/index.css';

import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Confirm from './components/Confirm/Confirm';
//import Landing from './components/Landing/Landing';
//import Home from './components/Home/Home';
import Placeholder from "./components/Placeholder/Placeholder";


Amplify.configure(config);

const App = () => {
  
  return (
    <Router>
      <div className="app-container">
        <Switch>
          <Redirect exact path="/" to='/home'/>
          <Route path="/home" component={Placeholder}/>
          {/* <Route path="/home" component={Landing}/> */}
          <Route path="/register" component={Register}/>
          <Route path="/login" component={Login}/>
          <Route path="/confirm" component={Confirm}/>
          {/* <Route path="/" component={Home}  /> */}
          <Route path="/" component={Placeholder}  />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
