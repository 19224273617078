import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import './Confirm.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";
import {Alert, Button, Card} from 'ui-neumorphism';

const Confirm = () => {
  const history = useHistory();
  const location = useLocation();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const setCodeVal = (event)=> {
    setCode(event.target.value);
  };

  const confirm = () => {
    Auth.confirmSignUp(location.state.username, code)
      .then((res) => {
        // Actions.signin();
        history.push('/listings');
      })
      .catch((err) => {
        setErrorMessage(err.message);
        // this.showFlashMessage(err.message, "red");
      });
  }

  const resendConfirmationCode = async () => {
    try {
      setLoading(true);
      await Auth.resendSignUp(location.state.username);
    } catch (err) {
      console.log("error resending code: ", err);
    }
    setLoading(false);
  };

  return (<div className="container">
              {errorMessage && <Alert type='error'>{errorMessage}</Alert>}
              <Card className="container" data-testid="Confirm">
                <h1>Verify Email</h1>
                <Card rounded className="text" inset><input value={code} onChange={setCodeVal}  placeholder="Verification Code" /></Card>
                <Button rounded disabled={loading}  className="btn" onClick={confirm} color='#31344b'>Confirm</Button>
                <div><div className="link" onClick={resendConfirmationCode}>Resend Code</div></div>
              </Card>
          </div>
  );
}

Confirm.propTypes = {};

Confirm.defaultProps = {};

export default Confirm;
