import React from 'react';
// import PropTypes from 'prop-types';
import './NavBarHome.scss';
import {Alert, Button, IconButton} from 'ui-neumorphism';
import { Auth } from 'aws-amplify';
import {useHistory} from 'react-router-dom';
import {FiHome, FiLogIn} from 'react-icons/fi';
import { RiAccountCircleLine } from 'react-icons/ri';

const NavBarHome = ({hasButtons}) => {
  const history = useHistory();

  const navigateToUrl = (url) => {
    history.push(url);
  }

  const checkLoginInStatusAndRedirect = () => {
    Auth.currentUserInfo().then(res=> {
      navigateToUrl(res? '/listings': '/login');
    }).catch(err=> {
      console.log(err)
    });
  }

  const register = () => {
    Auth.currentUserInfo().then(res=> {
      navigateToUrl('/register');
    }).catch(err=> {
      console.log(err)
    });
  }

  return (<div data-testid="NavBarHome" className="header">
            <div className="header-card">
              <div className="left">
                <IconButton size='large' onClick={() => navigateToUrl('/')} className="round-btn home" rounded text={false} ><FiHome size={20} color="#4267B2" /></IconButton>
                <Alert inset type='success'>
                  <span className="brand">RENTUITIV</span>
                  <span className="stage">ALPHA</span>
                </Alert>
              </div>
              <div className="right">
                {hasButtons ?<div><Button className="btn" onClick={checkLoginInStatusAndRedirect}><FiLogIn style={{marginRight: 10}} size={20}/>Login</Button>
                <Button className="btn" onClick={register}><RiAccountCircleLine style={{marginRight: 10}} size={20}/>Register</Button></div>: null}
              </div>
            </div>
          </div>
)};

NavBarHome.propTypes = {};

NavBarHome.defaultProps = {};

export default NavBarHome;
