import React, {useState} from 'react';
/* import PropTypes from 'prop-types'; */
import './Register.scss';
import { Auth } from "aws-amplify";
import {Alert, Button, Card, Switch} from 'ui-neumorphism';
import { useHistory } from 'react-router-dom';
// import Footer from '../Footer/Footer';
import NavBarHome from '../NavBarHome/NavBarHome';

const Register = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isTenant, setIsTenant] = useState(false);
  

  const setUsernameVal = (event) => {
    setUsername(event.target.value);
    setEmail(event.target.value);
  };

  const setPasswordVal = (event) => {
    setPassword(event.target.value);
  };

  const setPhoneVal = (event) => {
    setPhone(event.target.value);
  };

  const register = () => {
    setLoading(true);
    Auth.signUp({
      username,
      password,
      attributes: { email, phone_number, "custom:userType": isTenant ? 'Tenant' : 'Lessor' },
    })
      .then((res) => {
        history.push({pathname: '/confirm', state: {username}});
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
        // this.showFlashMessage(err.message, "red");
      });
  }

  const goToLogin = () => {
    history.push('/login');
  }

  const goToPasswordReset = () => {
    history.push('/reset-password');
  }

  const toggleUserType = () => {
    setIsTenant(!isTenant);
  }

  return (
    <div>
      <NavBarHome />
      <div className="container">
        <Card className="container" data-testid="Register">
            <h1>Register</h1>
            {errorMessage && <Alert type='error'>{errorMessage}</Alert>}
            <Card rounded className="text" inset><input value={username} onChange={setUsernameVal}  placeholder="Email" /></Card>
            <Card rounded className="text" inset><input type="password" value={password} onChange={setPasswordVal}  placeholder="Password" /></Card>
            <Card rounded className="text" inset><input type="text" value={phone_number} onChange={setPhoneVal}  placeholder="Phone Number" /></Card>
            <Switch value={isTenant} onChange={toggleUserType} color='var(--success)' label={isTenant? 'Tenant' : 'Lessor'} />
            <Button rounded disabled={loading} className="btn" onClick={register} color='#31344b'>Register</Button>
            <div className="links-flex">
              <div className="link" onClick={goToLogin}>Sign in</div>
              <div className="link" onClick={goToPasswordReset}>Forgot Password?</div>
            </div>
        </Card>
      </div>
    </div>
  );
}
  

Register.propTypes = {};

Register.defaultProps = {};

export default Register;
