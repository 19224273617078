import React, {useState} from 'react';
/* import PropTypes from 'prop-types'; */
import classes from './Placeholder.module.scss';
// import { NeuButton, NeuTextInput } from "neumorphism-react";
import { RiFacebookLine, RiLinkedinLine, RiTwitterLine } from 'react-icons/ri';
import { FaRegPaperPlane } from 'react-icons/fa';
import {addSubscriber} from '../../services/SubscribeService';
import {Alert, Button, Card, IconButton} from 'ui-neumorphism';

const Placeholder = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [messageError, setErrorMessage] = useState('');
  const [messageSuccess, setSuccessMessage] = useState('');

  const isEmailValid = () => {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email); 
  }

  const setEmailVal = (event) => {
    setEmail(event.target.value);
  } 
  const addUser = () => {
    setLoading(true);
    if(isEmailValid()) {
      addSubscriber(email).then(message => {
        setSuccessMessage(message);
        setErrorMessage('');
        setLoading(false);
      }).catch(err=> {
        console.log(err);
        setErrorMessage(err.message);
        setSuccessMessage('');
        setLoading(false);
      });
    } else {
      setSuccessMessage('');
      setErrorMessage('Please enter a valid Email ID');
      setLoading(false);
    }
  }
  
  return (
  <div>
      <div className={classes.Placeholder}>
        {messageError && <Alert type='error'>{messageError}</Alert>}
        {messageSuccess && <Alert type='success'>{messageSuccess}</Alert>}
        <h1>We Are Working On Something Amazing!</h1>
        <p>If you would like to get notified when our website goes live, subscribe to our mailing list!</p>
        <Card rounded className={classes.Text} inset><input value={email} onChange={setEmailVal}  placeholder="Enter Your Email" /></Card>
        <Button rounded disabled={loading}  className={classes.Notify} onClick={addUser} color='#31344b'><FaRegPaperPlane style={{marginRight: 10}} />Notify Me!</Button>
        <div className={classes.BtnContainer}>
          <IconButton size='large' className={classes.Round} rounded text={false} onClick={() => console.log('facebook')}><RiFacebookLine size={20} color="#4267B2" /></IconButton>
          <IconButton size='large' className={classes.Round} rounded text={false} onClick={() => console.log('linkedin')}><RiLinkedinLine size={20} color="#2867B2" /></IconButton>
          <IconButton size='large' className={classes.Round} rounded text={false} onClick={() => console.log('twitter')}><RiTwitterLine  size={20} color="#1DA1F2" /></IconButton>
        </div>
      </div>
  </div>);
};

Placeholder.propTypes = {};

Placeholder.defaultProps = {};


export default Placeholder;
