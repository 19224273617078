/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:066b6335-b007-425a-bfaf-a3c4cb6cbf9d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MZnaEJKfT",
    "aws_user_pools_web_client_id": "6dcj93ke2rq67h3i517gsdits4",
    "oauth": {}
};


export default awsmobile;
