import { v4 as uuidv4 } from 'uuid';

const SUBSCRIBE_URL = "https://1nr8ilyq3i.execute-api.us-east-1.amazonaws.com/prod/subscribe";

export const addSubscriber = (email) => {
    const body = {
        id: uuidv4(),
        email
    }
    return fetch(SUBSCRIBE_URL,{
        method: 'POST',
        body: JSON.stringify(body)
    }).then((response) => {
        return 'Your Email ID has been added successfully on our early users list. Stay tuned!';
      })
      .catch((err) => {
        console.log("Error creating subscriber", err);
      });
}